import { render, staticRenderFns } from "./ListClub.vue?vue&type=template&id=5af1a550"
import script from "./ListClub.vue?vue&type=script&lang=js"
export * from "./ListClub.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CImage: require('@chakra-ui/vue').CImage, CText: require('@chakra-ui/vue').CText, CLink: require('@chakra-ui/vue').CLink, CButton: require('@chakra-ui/vue').CButton, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CButtonGroup: require('@chakra-ui/vue').CButtonGroup})
