<template>
  <CBox>
    <Loading :active="isLoading" />
    <JoinClub
      :isModalOpen="isModalOpen"
      :is_need_approval="currentIsNeedApproval"
      :slug="currentSlug"
      :title="currentTitle"
      :rule="currentRule"
      @closeModal="closeModal"
    />
    <CBox v-if="clubs.data && clubs.data.length > 0">
      <CBox v-for="(v, i) in clubs.data" :key="i">
        <CBox
          bg="white"
          overflow="hidden"
          borderBottom="1px solid var(--Neutral-Light-Gray, #EFF1F5)"
          rounded="md"
          p="4"
          my="2"
        >
          <CFlex class="responsive-flex" justify="space-between" mt="2" px="2">
            <CFlex border="red" justify="space-beetween">
              <CImage
                :src="
                  v.logo ? v.logo : require('@/assets/img/default-profile.png')
                "
                alt="Corner Image"
                w="80px"
                h="80px"
                mb="4"
                mr="10px"
                borderRadius="5"
              />
              <CBox w="300px" border="red">
                <CLink
                  :href="`/clubs/${v.slug}`"
                  color="#0278B7"
                  d="inline-block"
                  alignItems="center"
                >
                  <CText
                    color="#0278B7"
                    fontWeight="600"
                    fontSize="16px"
                    style="word-wrap: break-word"
                    max-w="250px"
                    >{{ v.name }}</CText
                  >
                </CLink>
                <CText
                  fontSize="12px"
                  color="#000"
                  fontWeight="600"
                  style="word-wrap: break-word"
                  max-w="250px"
                  >{{ v.location }}</CText
                >
                <CButton
                  v-if="
                    (currentUser &&
                      !currentUser.urole_id &&
                      v.is_joined === 0 &&
                      v.status === 'decline') ||
                    (currentUser &&
                      !currentUser.urole_id &&
                      v.is_joined === 0 &&
                      v.status === 'not_joined') ||
                    (currentUser &&
                      !currentUser.urole_id &&
                      v.is_joined === 0 &&
                      v.status === 'accept')
                  "
                  :d="['block', 'none', 'none', 'none']"
                  bg="bgButtonClub"
                  color="white"
                  :_hover="{ opacity: '0.8' }"
                  size="sm"
                  fontSize="11px"
                  fontWeight="600"
                  w="fit-content"
                  px="16px"
                  py="8px"
                  mt="2"
                  @click="openModal(i)"
                >
                  Join
                </CButton>
                <CBox
                  v-else-if="v.status === 'pending'"
                  :d="['block', 'none', 'none', 'none']"
                  mt="2"
                >
                  <CImage
                    :src="require('@/assets/icon/icon-pending.svg')"
                    alt="Waiting Approval"
                    title="Waiting Approval"
                    w="16px"
                  />
                </CBox>
                <CButton
                  v-else-if="!currentUser.urole_id && v.is_joined !== 0"
                  :d="['block', 'none', 'none', 'none']"
                  :bg="isUrlCopied !== i ? 'white' : 'main'"
                  :color="isUrlCopied !== i ? 'main' : 'white'"
                  :border="isUrlCopied !== i ? '#29AAFE 1px solid' : ''"
                  :_hover="{ opacity: '0.8' }"
                  w="fit-content"
                  px="16px"
                  py="8px"
                  fontSize="11px"
                  fontWeight="600"
                  mt="2"
                  @click="copyUrl(i)"
                >
                  Share
                </CButton>
                <CText
                  v-if="
                    (v.role === 'owner' && mode === 'my' && v.total_request) ||
                    (v.role === 'admin' && mode === 'my' && v.total_request)
                  "
                  fontSize="10px"
                  color="#000"
                  fontWeight="light"
                >
                  {{ v.total_request }} Request
                </CText>
                <JoinClub
                  :is_need_approval="currentIsNeedApproval"
                  :slug="v.slug"
                  :name="v.name"
                  :isInviteModalOpen="currentClubIndex === i"
                  @closeModal="closeModal"
                />
              </CBox>
            </CFlex>
            <CBox
              w="400px"
              d="flex"
              :alignItems="['start', 'center', 'center', 'center']"
              :flexDirection="['column', 'row', 'row', 'row']"
              :justifyContent="[
                'flex-start',
                'space-between',
                'space-between',
                'space-between',
              ]"
              flexWrap="wrap"
            >
              <CText
                color="#000"
                fontWeight="600"
                fontSize="16px"
                d="flex"
                alignItems="center"
                textAlign="end"
              >
                {{ v.total_member }}
                <CText fontSize="10px" fontWeight="400" ml="1"> Members</CText>
              </CText>
              <CText color="#000" fontWeight="600" fontSize="14px">{{
                getTypeLabel(v.type)
              }}</CText>
              <CButton
                v-if="
                  (currentUser &&
                    !currentUser.urole_id &&
                    v.is_joined === 0 &&
                    v.status === 'decline') ||
                  (currentUser &&
                    !currentUser.urole_id &&
                    v.is_joined === 0 &&
                    v.status === 'not_joined') ||
                  (currentUser &&
                    !currentUser.urole_id &&
                    v.is_joined === 0 &&
                    v.status === 'accept') ||
                    !currentUser
                "
                :d="['none', 'block', 'block', 'block']"
                bg="bgButtonClub"
                color="white"
                :_hover="{ opacity: '0.8' }"
                my="2"
                size="sm"
                fontSize="16px"
                fontWeight="600"
                px="4"
                @click="currentUser ? openModal(i) : redirectToSignIn()"
              >
                Join
              </CButton>
              <CBox
                v-else-if="v.status === 'pending'"
                px="4"
                py="2"
                :d="['none', 'block', 'block', 'block']"
              >
                <CImage
                  :src="require('@/assets/icon/icon-pending.svg')"
                  alt="Waiting Approval"
                  title="Waiting Approval"
                  w="20px"
                  mr="2"
                />
              </CBox>
              <CButton
                v-else-if="
                  (!currentUser.urole_id && v.is_joined !== 0) ||
                  !currentUser ||
                  currentUser.urole_id
                "
                :d="['none', 'block', 'block', 'block']"
                :bg="isUrlCopied !== i ? 'white' : 'main'"
                :color="isUrlCopied !== i ? 'main' : 'white'"
                :border="isUrlCopied !== i ? '#29AAFE 1px solid' : ''"
                :_hover="
                  isUrlCopied !== i ? { opacity: '0.8' } : { opacity: '1' }
                "
                my="2"
                size="sm"
                @click="detailClubHandler(v.slug)"
                fontWeight="600"
              >
                Detail
              </CButton>
            </CBox>
          </CFlex>
        </CBox>
      </CBox>
      <CBox my="4" d="flex" justifyContent="center" alignItems="center">
        <CButtonGroup isAttached variant="outline" spacing="2" bg="white">
          <CButton
            @click="setCurrentPage(currentPage - 1)"
            :disabled="currentPage === 1"
            fontWeight="400"
          >
            Prev
          </CButton>

          <template v-if="totalPages <= 5">
            <CButton
              v-for="page in totalPages"
              :key="page"
              @click="setCurrentPage(page)"
              :disabled="currentPage === page"
              fontWeight="400"
            >
              {{ page }}
            </CButton>
          </template>

          <template v-else>
            <template v-if="currentPage <= 3">
              <CButton
                v-for="page in 5"
                :key="page"
                @click="setCurrentPage(page)"
                :disabled="currentPage === page"
                fontWeight="400"
              >
                {{ page }}
              </CButton>
              <CButton :disabled="true">...</CButton>
              <CButton @click="setCurrentPage(totalPages)" fontWeight="400">
                {{ totalPages }}
              </CButton>
            </template>

            <template v-else-if="currentPage >= totalPages - 2">
              <CButton @click="setCurrentPage(1)" fontWeight="400">1</CButton>
              <CButton :disabled="true" fontWeight="400">...</CButton>
              <CButton
                v-for="page in 5"
                :key="totalPages - 5 + page"
                @click="setCurrentPage(totalPages - 5 + page)"
                :disabled="currentPage === totalPages - 5 + page"
                fontWeight="400"
              >
                {{ totalPages - 5 + page }}
              </CButton>
            </template>

            <template v-else>
              <CButton @click="setCurrentPage(1)" fontWeight="400">1</CButton>
              <CButton :disabled="true" fontWeight="400">...</CButton>
              <CButton
                v-for="page in 3"
                :key="currentPage - 1 + page"
                @click="setCurrentPage(currentPage - 1 + page)"
                :disabled="currentPage === currentPage - 1 + page"
                fontWeight="400"
              >
                {{ currentPage - 1 + page }}
              </CButton>
              <CButton :disabled="true" fontWeight="400">...</CButton>
              <CButton @click="setCurrentPage(totalPages)" fontWeight="400">
                {{ totalPages }}
              </CButton>
            </template>
          </template>

          <CButton
            @click="setCurrentPage(currentPage + 1)"
            :disabled="currentPage === totalPages"
            fontWeight="400"
          >
            Next
          </CButton>
        </CButtonGroup>
      </CBox>
    </CBox>
    <CBox v-else>
      <CBox
        bg="white"
        d="flex"
        alignItems="center"
        justifyContent="center"
        rounded="md"
        py="2"
        shadow="sm"
        mt="4"
      >
        <CText v-if="!query" textAlign="center"
          >You don't have a club yet</CText
        >
        <CText v-else textAlign="center"
          >Clubs containing the word '<CText
            d="inline-block"
            fontWeight="semibold"
            >{{ query }}</CText
          >', were not found</CText
        >
      </CBox>
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CImage, CText, CFlex, CButton, CLink } from "@chakra-ui/vue";
import { GET_CLUBS } from "@/store/club.module";
import JoinClub from "@/components/modal/JoinClub.vue";
import Swal from "sweetalert2";
import config from "@/ConfigProvider";

export default {
  components: {
    CBox,
    CImage,
    CText,
    CFlex,
    CButton,
    CLink,
    JoinClub,
    Loading,
  },
  props: {
    mode: String,
  },
  data() {
    return {
      currentClubIndex: null,
      isLoading: true,
      currentPage: 1,
      currentSlug: "",
      currentTitle: "",
      currentRule: "",
      currentIsNeedApproval: "",
      isModalOpen: false,
      isUrlCopied: -1,
    };
  },
  computed: {
    currentUser() {
      return this.$store.getters.currentUser;
    },
    clubs() {
      return this.$store.getters.clubs || [];
    },
    totalPages() {
      return Math.ceil(this.clubs.meta.total / this.clubs.meta.limit);
    },
    query() {
      return this.$route.query.q;
    },
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    redirectToSignIn() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
    detailClubHandler(v) {
      const basePath = this.$route.params.track
        ? `/track/${this.$route.params.track}/clubs/${v}`
        : `/clubs/${v}`;

      const newPath = `${basePath}`;
      window.location.href = newPath;
    },
    async getData() {
      try {
        var params = {
          data: {
            page: this.currentPage,
            limit: 10,
            sortBy: "name",
            myClub: false,
            keyword: this.query,
          },
        };
        if (this.mode === "my") {
          params = {
            data: {
              page: this.currentPage,
              limit: 10,
              sortBy: "total_request",
              sort: "DESC",
              myClub: true,
              keyword: this.query,
            },
          };
        }
        await this.$store.dispatch(GET_CLUBS, params);
      } catch (err) {
        console.log(err);
      }
    },
    formatDate(dateString) {
      const months = [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ];

      const date = new Date(dateString);
      const monthNumber = date.getMonth() + 1; // Months are zero-based
      const year = date.getFullYear();

      return `${monthNumber} ${months[monthNumber - 1]} ${year}`;
    },
    openModal(index) {
      this.currentSlug = this.clubs.data[index].slug;
      this.currentTitle = this.clubs.data[index].name;
      this.currentRule = this.clubs.data[index].rule;
      this.currentIsNeedApproval = this.clubs.data[index].is_need_approval;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
      this.getData();
    },
    getTypeLabel(type) {
      return type === "komunitas"
        ? "Community"
        : type === "kampus"
        ? "University"
        : type === "perusahaan"
        ? "Company"
        : "Event";
    },
    setCurrentPage(page) {
      this.currentPage = page;
      this.getData();
    },
    copyUrl(index) {
      const inviteUrl = window.location + "/" + this.clubs.data[index].slug;
      const dummyInput = document.createElement("input");
      document.body.appendChild(dummyInput);
      dummyInput.value = inviteUrl;
      dummyInput.select();
      document.execCommand("copy");
      document.body.removeChild(dummyInput);

      // Update the state to indicate that the URL has been copied
      this.isUrlCopied = index;
      Swal.fire({
        title: "Copied",
        text: "Club " + this.clubs.data[index].name + " url ready to share.",
        icon: "success",
      });
      // Reset the state after a certain duration (e.g., 2 seconds)
      setTimeout(() => {
        this.isUrlCopied = -1;
      }, 2000);
    },
  },
  watch: {
    mode: {
      immediate: true,
      handler(newMode, oldMode) {
        if (newMode !== oldMode) {
          this.currentPage = 1;
          this.getData();
        }
      },
    },
  },
};
</script>
