<template>
  <Loading v-if="isLoading" :active="isLoading" />
  <CBox
    v-else-if="!isLoading && errorMessage !== 'club not found'"
    boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
    borderRadius="5"
    w="100%"
    h="fit-content"
    my="5"
    bg="white"
    p="24px"
  >
    <CBox>
      <CText fontSize="18px" fontWeight="600"> Total Club </CText>
      <CText fontSize="24px" fontWeight="600">
        {{ clubTotal }}
      </CText>
    </CBox>
    <CBox mt="4" v-if="!currentUser.urole_id && currentUser">
      <CText fontSize="18px" fontWeight="600"> Total Join Club </CText>
      <CText fontSize="24px" fontWeight="600">
        {{ clubTotalJoined }}
      </CText>
    </CBox>
    <CBox mt="4" v-if="!currentUser.urole_id && currentUser">
      <CText fontSize="16px" fontWeight="400"> Total request </CText>
      <CText fontSize="24px" fontWeight="600">
        {{ requestsTotal.total_request ? requestsTotal.total_request : 0 }}
      </CText>
    </CBox>
    <CText
      v-if="!currentUser"
      color="main"
      :_hover="{ textDecoration: 'underline' }"
      cursor="pointer"
      fontSize="12px"
      rounded="md"
      d="flex"
      w="fit-content"
      mt="1"
      alignItems="center"
      fontWeight="600"
      @click.native="handleLogin"
    >
      Login
    </CText>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import { CBox, CText } from "@chakra-ui/vue";
import {
  GET_CLUB_REQUESTS_TOTAL,
  GET_CLUB_TOTAL,
  GET_CLUB_TOTAL_JOINED,
} from "@/store/club.module";
import config from "@/ConfigProvider.js";

export default {
  components: {
    Loading,
    CBox,
    CText,
  },
  data() {
    return {
      isLoading: true,
      urlGethired: config.value("GETHIRED_WEB_URL"),
    };
  },
  async mounted() {
    try {
      await this.getData();
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    async getData() {
      try {
        await this.$store.dispatch(GET_CLUB_TOTAL);
        if (this.currentUser) {
          await this.$store.dispatch(GET_CLUB_TOTAL_JOINED);
          if (!JSON.parse(this.currentUser).urole_id) {
            await this.$store.dispatch(GET_CLUB_REQUESTS_TOTAL);
          }
        }
        this.isLoading = false;
      } catch (err) {
        console.log(err);
      }
    },
    handleLogin() {
      window.location.href =
        config.value("GETHIRED_WEB_URL") +
        `/signin?next=${window.location.href}`;
    },
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    clubTotal() {
      return this.$store.getters.clubTotal || 0;
    },
    clubTotalJoined() {
      return this.$store.getters.clubTotalJoined || 0;
    },
    requestsTotal() {
      return this.$store.getters.requestsTotal || 0;
    },
  },
};
</script>
