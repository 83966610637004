<template>
  <Loading v-if="isLoading" :active="isLoading" />
  <CBox v-else d="flex" justifyContent="space-between" flexWrap="wrap">
    <CBox
      borderRadius="5px"
      boxShadow="0px 4px 10px rgba(0, 0, 0, 0.1)"
      :w="['100%', '100%', '74%', '74%']"
      mt="5"
      mx="auto"
      bg="white"
      py="24px"
      :px="['10px', '24px', '24px', '24px']"
    >
      <CFlex
        alignItems="center"
        justifyContent="space-between"
        flexWrap="wrap"
        mb="1em"
      >
        <CText fontStyle="extrabold" fontSize="3xl" fontWeight="semibold">
          Clubs
        </CText>
        <CLink
          v-if="!currentUser.urole_id && currentUser"
          :href="`/clubs/new`"
          color="main"
          d="inline-block"
          :_hover="{ textDecoration: 'none', opacity: 0.8 }"
          ml="2"
        >
          <CText
            bg="bgButtonClub"
            color="white"
            @click="createClub"
            w="fit-content"
            px="14px"
            py="4px"
            borderRadius="5"
            d="flex"
            size="0"
            justifyContent="center"
            fontSize="12px"
            alignItems="center"
            fontWeight="600"
            lineHeight="26px"
          >
            <CImage
              :src="require('@/assets/icon/icon-plus.svg')"
              mr="1"
              w="15px"
            />
            Create Club</CText
          >
        </CLink>
      </CFlex>

      <!-- Search Bar -->
      <form @submit.prevent="handleSearch">
        <CBox d="flex" flexWrap="wrap">
          <CInputGroup
            v-if="!currentUser.urole_id"
            :w="['100%', '79%', '79%', '79%']"
            mr="auto"
          >
            <CInput
              placeholder="Search"
              variant="outline"
              borderRadius="md"
              mb="2"
              v-model="search"
              pr="10"
            />
            <CInputRightElement width="4.5rem">
              <CImage
                :src="require('@/assets/icon/icon-search.svg')"
                mr="1"
                w="20px"
                cursor="pointer"
                @click.native="handleSearch"
              />
            </CInputRightElement>
          </CInputGroup>
          <CInputGroup v-else w="100%">
            <CInput
              placeholder="Search..."
              variant="outline"
              borderRadius="md"
              mb="2"
              mr="auto"
              v-model="search"
              position="relative"
              rightIcon="search"
            />
            <CInputRightElement width="4.5rem">
              <CImage
                :src="require('@/assets/icon/icon-search.svg')"
                mr="1"
                w="20px"
                cursor="pointer"
                @click.native="handleSearch"
              />
            </CInputRightElement>
          </CInputGroup>
          <CBox :w="['100%', '20%', '20%', '20%']">
            <CSelect
              v-if="!currentUser.urole_id"
              v-model="mode"
              variant="outline"
              borderRadius="md"
              d="flex"
              ml="auto"
              w="20px"
              bg="#EFF1F5"
            >
              <option value="all">All Clubs</option>
              <option value="my">My Clubs</option>
            </CSelect>
          </CBox>
        </CBox>
      </form>
      <CBox v-if="!isLoading">
        <ListClub :mode="mode" :search="search" />
      </CBox>
      <CBox v-else>
        <CBox
          bg="white"
          d="flex"
          alignItems="center"
          justifyContent="center"
          rounded="md"
          shadow="sm"
          py="2"
          mt="4"
        >
          <CText textAlign="center">Searching...</CText>
        </CBox>
      </CBox>
    </CBox>
    <CBox
      v-if="!isLoading && errorMessage !== 'club not found'"
      :w="['100%', '100%', '25%', '25%']"
    >
      <SidebarListClub />
    </CBox>
  </CBox>
</template>

<script>
import Loading from "vue-loading-overlay";
import {
  CBox,
  CText,
  CInputGroup,
  CInput,
  CInputRightElement,
  CSelect,
} from "@chakra-ui/vue";
import ListClub from "@/components/list/ListClub.vue";
import SidebarListClub from "@/components/sidebar/ListClub.vue";
import config from "@/ConfigProvider.js";

export default {
  metaInfo() {
    return {
      title: "Club",
      titleTemplate: "Devcode - %s",
    };
  },
  name: "Clubs",
  components: {
    Loading,
    CBox,
    CText,
    CInputGroup,
    CInput,
    CSelect,
    ListClub,
    CInputRightElement,
    SidebarListClub,
  },
  computed: {
    currentUser() {
      const user = this.$store.getters.currentUser;
      return user;
    },
    query() {
      return this.$route.query.q;
    },
  },
  data() {
    return {
      mode: "all",
      showAlert: true,
      search: this.$route.query.q,
      isLoading: false,
      urlGethired: config.value("GETHIRED_WEB_URL"),
    };
  },
  methods: {
    async handleSearch() {
      const queryParams = { q: this.search };
      if (this.search == "") {
        this.isLoading = true;
        await this.$router.push({ query: {} });
        window.location.reload();
      } else if (this.search != this.$route.query.q) {
        this.isLoading = true;
        await this.$router.push({ query: queryParams });
        window.location.reload();
      }
    },
    createClub() {
      this.showAlert = true;
    },
    hideAlert() {
      this.showAlert = false;
    },
  },
};
</script>
